import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

function PortfolioPage() {
  const { allWpPortfolio } = useStaticQuery(
    graphql`
      query getWpPortfolio {
        allWpPortfolio {
          nodes {
            id
            title
            uri
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      placeholder: TRACED_SVG
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  // console.log("========== portfolios object with `nodes` array  =============")
  // console.log(allWpPortfolio)
  // console.log("====================================")

  // ************ PORTFOLIO V2 FADE IN / OUT *************
  const [daytime, setDaytime] = useState(false)
  const updateDaytime = d => {
    setDaytime(d)
  }
  let timerToggleDaytime
  useEffect(() => {
    if (!timerToggleDaytime) {
      timerToggleDaytime = setInterval(() => {
        updateDaytime(!daytime)
      }, 3000)
    }
    return () => {
      if (timerToggleDaytime) {
        clearTimeout(timerToggleDaytime)
      }
    }
  }, [daytime])

  return (
    <Layout>
      <SEO
        keywords={[
          `web development`,
          `gatsby`,
          `tailwind`,
          `react`,
          `tailwindcss`,
        ]}
        description="Portfolio of ABC Interactive LLC. ABC Interactive LLC is a small website and mobile app development
        company located in California. Services include modern website and app development."
        title="Portfolio of ABC Interactive LLC"
      />

      <section className="max-w-screen-lg m-auto mb-10 mt-20 px-5">
        <h1 className="text-center p-3 mt-5 mb-4 text-2xl font-bold text-white">
          Portfolio
        </h1>
        <div className="flex max-w-2xl mb-3 mx-auto bg-white rounded-lg shadow-md dark:bg-gray-800">
          <div className="p-4">
            <h3 className="text-black dark:text-gray-400 mb-2 font-family-opensans">
              ABC Interactive LLC is a small website and mobile app development
              company located in California. Founded by Andrew Chew in 2015
              after a decade of building engaging online experiences for
              companies large and small.
            </h3>
            <p className="text-gray-600 dark:text-gray-400 font-family-opensans">
              These are some examples of recent projects and past client work on
              my own or working with an agency.
            </p>
          </div>
        </div>
        <div className="masonry sm:masonry-sm md:masonry-md op">
          <div
            className={`relative
          max-w-2xl mb-3 mx-auto bg-white rounded-lg shadow-md dark:bg-gray-800 overflow-hidden`}
          >
            <Link to="https://squirreledworld.abci.io/game/">
              <div className="w-full h-full relative">
                <div>
                  <StaticImage
                    src="../../content/assets/squirreledonline_day.jpg"
                    alt={"ABC Interactive LLC portfolio"}
                    className={`${daytime ? "opacity-100" : "opacity-0"}
                              w-full rounded-md shadow-md transition duration-1000 ease-in-out
            `}
                  />
                </div>
                <div className="absolute top-0 left-0">
                  <StaticImage
                    src="../../content/assets/squirreledonline_night.jpg"
                    alt={"ABC Interactive LLC portfolio"}
                    className={`${daytime ? "opacity-0" : "opacity-100"}
                               w-full rounded-md shadow-md transition duration-1000 ease-in-out
                `}
                  />
                </div>
                <div
                  className="w-full h-full opacity-50 hover:opacity-100
                      absolute top-0
                      transition ease-in-out duration-300"
                >
                  <div
                    className="w-full h-1/2 absolute bottom-0 left-0
                        bg-gray-100"
                  >
                    <h3 className="p-2 w-3/4 font-family-opensans">
                      A JavaScript Squirreled® game written in React/Redux.
                    </h3>
                    <div
                      className="absolute
                      bottom-0 right-0 w-1/4  h-full
                      text-center flex justify-items-center align-middle
                        text-white bg-gray-900"
                    >
                      <p className="p-2 text-xs text-left">View Game</p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div
            className={`relative
            max-w-2xl mb-3 mx-auto bg-white rounded-lg shadow-md dark:bg-gray-800 overflow-hidden`}
          >
            <Link to="https://abci.io/v2">
              <div className="w-full h-full relative">
                <div>
                  <StaticImage
                    src="../../content/assets/portfolio_v2_day.jpg"
                    alt={"ABC Interactive LLC portfolio"}
                    className={`${daytime ? "opacity-100" : "opacity-0"}
                              w-full rounded-md shadow-md transition duration-1000 ease-in-out
            `}
                  />
                </div>
                <div className="w-full h-full absolute top-0 left-0">
                  <StaticImage
                    src="../../content/assets/portfolio_v2_night.jpg"
                    alt={"ABC Interactive LLC portfolio"}
                    className={`${daytime ? "opacity-0" : "opacity-100"}
                                w-full rounded-md shadow-md transition duration-1000 ease-in-out
                  `}
                  />
                </div>
                <div
                  className="w-full h-full opacity-50 hover:opacity-100
                      absolute top-0
                      transition ease-in-out duration-300"
                >
                  <div
                    className="w-full h-1/2 absolute bottom-0 left-0
                        bg-gray-100"
                  >
                    <h3 className="p-2 w-3/4 font-family-opensans">
                      A parallax version of my portfolio written in React JS.
                    </h3>
                    <div
                      className="absolute
                      bottom-0 right-0 w-1/4  h-full
                      text-center flex justify-items-center align-middle
                        text-white bg-gray-900"
                    >
                      <p className="p-2 text-xs text-left">check it out!</p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          {allWpPortfolio.nodes.map((portfolioItem, index) => {
            const title = portfolioItem.title

            const featuredImage = {
              data:
                portfolioItem.featuredImage?.node?.localFile?.childImageSharp
                  ?.gatsbyImageData,
              alt: portfolioItem.featuredImage?.node?.alt || ``,
            }
            // console.log("featuredImage", featuredImage)
            let aspectRand = Math.random()
            // console.log("aspectRand", aspectRand)

            return (
              <div
                key={`_${index}`}
                className="flex max-w-2xl mb-3 mx-auto bg-white rounded-lg shadow-md dark:bg-gray-800 overflow-hidden"
              >
                <div className={`w-full h-full relative`}>
                  {featuredImage?.data && (
                    <Link to={portfolioItem.uri}>
                      <GatsbyImage
                        image={featuredImage.data}
                        alt={featuredImage.alt}
                        className={`object-cover ${
                          aspectRand < 0.8 ? "aspect-video" : "aspect-square"
                        }`}
                      />
                      <div
                        className="w-full h-full opacity-50 hover:opacity-100
                      absolute top-0
                      transition ease-in-out duration-300"
                      >
                        <div
                          className="w-full h-1/2 absolute bottom-0 left-0
                        bg-gray-100"
                        >
                          <h3 className="p-2 w-3/4 font-family-opensans">
                            {portfolioItem.title}
                          </h3>
                          <div
                            className="absolute
                      bottom-0 right-0 w-1/4  h-full
                      text-center flex justify-items-center align-middle
                        text-white bg-gray-900"
                          >
                            <p className="p-2 text-xs text-left">
                              read more...
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default PortfolioPage
